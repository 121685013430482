import axios from 'axios';

export default function useAjax() {
  const ajaxUrl = document.querySelector('meta[name="ajax_url"]').content;

  const axiosInstance = axios.create({
    baseURL: ajaxUrl,
  });
  // axiosInstance.defaults.headers.common = { Accept: 'application/json' };

  return axiosInstance;
}
