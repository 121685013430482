/*
  Project: Jeziorak
  Author: Adam Kanigowski
 */

import cookies from './modules/cookies';
import menu from './modules/menu';
import posts from './modules/posts';
import select from './modules/select';
import slider from './modules/slider';

document.addEventListener('DOMContentLoaded', () => {
  menu();
  cookies();
  slider();
  posts();
  select();
});
