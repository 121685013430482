import { Swiper, Navigation } from 'swiper';
// import 'swiper/swiper.scss';

const slider = () => {
  // eslint-disable-next-line no-new
  new Swiper('.js-swiper', {
    slidesPerView: 1.5,
    spaceBetween: 16,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
    },
    navigation: {
      nextEl: '.js-players-slider-next',
      prevEl: '.js-players-slider-prev',
      disabledClass: 'c-slider__btn--disabled',
    },
    modules: [Navigation],
  });
};

export default slider;
