/* eslint-disable no-param-reassign */
import useAjax from './useAjax';

const posts = () => {
  async function getPosts(content, page) {
    const { type, perPage, mode, season } = content.dataset;
    return useAjax().post(null, null, {
      params: {
        action: 'get_paginated_posts',
        restUrl: `${
          document.querySelector('[data-rest-url]').dataset.restUrl
        }wp/v2/`,
        type,
        perPage,
        mode,
        page,
        season,
      },
    });
  }

  const buttons = document.querySelectorAll('.js-load-more');
  if (!buttons) return;

  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      const content = button
        .closest('.js-posts')
        .querySelector('.js-posts-wrapper');

      button.classList.add('is-loading', 'is-disabled');

      getPosts(content, button.dataset.page)
        .then((response) => {
          content.insertAdjacentHTML('beforeend', response.data);
          const pages = content.querySelector('.js-pages');

          if (pages && pages.dataset.pages === button.dataset.page) {
            button.classList.add('u-hidden');
          } else {
            button.dataset.page = parseInt(button.dataset.page, 10) + 1;
          }
        })
        .finally(() => {
          button.classList.remove('is-loading', 'is-disabled');
        });
    });
  });
};

export default posts;
