const select = () => {
  const inputSelect = document.querySelector('.js-select');

  inputSelect.addEventListener('change', (event) => {
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set(inputSelect.name, event.target.value);
    window.location.search = urlParams;
  });
};

export default select;
